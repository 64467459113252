import Footer from "../../components/Footer";
import Header from "../../components/Header";
import service from "../../assets/tugboat.jpg";

const Service = () => {
  return (
    <>
      <Header />
      <section className="space-top space-extra-bottom">
        <div className="container">
          <div className="row">
            <div className="page-img">
              <img src={service} alt="Project" />
            </div>
          </div>
          <div className="row flex-row-reverse">
            <div className="col-xxl-8 col-lg-7">
              <div className="page-single">
                <div className="page-content">
                  <h2 className="h3 page-title">Serviced Tugboats</h2>
                  <p>
                    Globally optimize highly efficient solution whereas
                    open-source application. Completely strategize quality
                    internal or "organic" sources for virtual e-business.
                    Phosfluorescently re-engineer enterprise markets via
                    value-added networks. Seamlessly restore inexpensive
                    e-markets vis-a-vis corporate intellectual capital.
                    Holisticly reinvent compelling niche markets via scalable
                    strategic.
                  </p>
                  <p className="mb-30">
                    Authoritatively scale business meta-services before
                    client-based technologies. Collaboratively strategize
                    synergistic scenarios rather than flexible action items.
                    Continually deliver market positioning convergence and
                    mission-critical infrastructures.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-lg-5">
              <aside className="sidebar-area">
                <div className="widget widget_info">
                  <h3 className="widget_title">Project Details</h3>
                  <div className="info-list">
                    <ul>
                      <li>
                        <strong>Project Category: </strong>
                        <span>Road Bridge</span>
                      </li>
                      <li>
                        <strong>Clients: </strong>
                        <span>David Malan</span>
                      </li>
                      <li>
                        <strong>Project Date: </strong>
                        <span>13 June, 2020</span>
                      </li>
                      <li>
                        <strong>Project End Date: </strong>
                        <span>22 July, 2021</span>
                      </li>
                      <li>
                        <strong>Location: </strong>
                        <span>NY - 2546 Streat, USA</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Service;
