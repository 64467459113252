import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";

const Footer = () => {
  return (
    <footer
      className="footer-wrapper footer-layout1"
      data-bg-src="/assets/img/bg/footer_bg_1.jpg"
    >
      <div className="widget-area" style={{ paddingTop: "30px" }}>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6 col-xl-3">
              <div className="widget footer-widget">
                <div className="as-widget-about">
                  <div className="about-logo">
                    <Link to="/">
                      <img src={logo} alt="logo" style={{ height: "120px" }} />
                    </Link>
                  </div>

                  <div className="as-social">
                    <a href="https://www.facebook.com/">
                      <i className="fab fa-facebook-f" />
                    </a>{" "}
                    <a href="https://www.twitter.com/">
                      <i className="fab fa-twitter" />
                    </a>{" "}
                    <a href="https://www.linkedin.com/">
                      <i className="fab fa-linkedin-in" />
                    </a>{" "}
                    <a href="https://www.whatsapp.com/">
                      <i className="fab fa-whatsapp" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-auto">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">QUICK LINKS</h3>
                <div className="menu-all-pages-container">
                  <ul className="menu">
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/projects">Projects</Link>
                    </li>

                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-auto">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">SERVICES</h3>
                <div className="menu-all-pages-container">
                  <ul className="menu">
                    <li>
                      <Link to="/services/industrial-catering">
                        Industrial Catering
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/research">Research & Analysis</Link>
                    </li>
                    <li>
                      <Link to="/services/interior-development">
                        Interior Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/virtual-design">
                        Virtual Design &amp; Build
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <p className="copyright-text">
                Copyright <i className="fal fa-copyright" />{" "}
                {new Date(Date.now()).getFullYear()} Isafred Limited. All Rights
                Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
