import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import catering from "../assets/catering.jpg";
import research from "../assets/research.jpg";

const Services = () => {
  return (
    <>
      <Header />
      <section className="space">
        <div className="container">
          <div className="title-area text-center">
            <div className="shadow-title">SERVICES</div>
            <span className="sub-title justify-content-center">
              <span className="shape left">
                <span className="dots" />
              </span>{" "}
              OUR SERVICES{" "}
              <span className="shape right">
                <span className="dots" />
              </span>
            </span>
            <h2 className="sec-title">Best Services For You</h2>
          </div>
          <div className="row gy-40">
            <div className="col-md-6 col-xl-4">
              <div className="service-block">
                <div className="service-block_img">
                  <img
                    src={catering}
                    alt="catering"
                    style={{ height: "350px", objectFit: "cover" }}
                  />
                </div>
                <div className="service-block_content">
                  <div className="service-block_icon">
                    <img src="/assets/img/icon/service_1_1.svg" alt="Icon" />
                  </div>
                  <h3 className="service-block_title">
                    <Link to="/services/industrial-catering">
                      Industrial Catering
                    </Link>
                  </h3>
                  {/* <p className="service-block_text">
                    Integrated market before enterprise wide e-commerce.
                    Competently actualize bleeding-edge testing.
                  </p> */}
                </div>
                <Link to="/services/industrial-catering" className="as-btn">
                  READ MORE
                  <i className="fas fa-arrow-right ms-2" />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="service-block">
                <div className="service-block_img">
                  <img
                    src={research}
                    alt="research"
                    style={{ height: "350px", objectFit: "cover" }}
                  />
                </div>
                <div className="service-block_content">
                  <div className="service-block_icon">
                    <img src="/assets/img/icon/service_1_2.svg" alt="Icon" />
                  </div>
                  <h3 className="service-block_title">
                    <Link to="/services/research">Research & Analysis</Link>
                  </h3>
                  {/* <p className="service-block_text">
                    Diestarbcs market before enterprise wide e-commerce.
                    Competently actualize bleeding-edge gesture.
                  </p> */}
                </div>
                <Link to="/services/research" className="as-btn">
                  READ MORE
                  <i className="fas fa-arrow-right ms-2" />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="service-block">
                <div className="service-block_img">
                  <img
                    src="/assets/img/service/service_4_3.jpg"
                    alt="service"
                  />
                </div>
                <div className="service-block_content">
                  <div className="service-block_icon">
                    <img src="/assets/img/icon/service_1_3.svg" alt="Icon" />
                  </div>
                  <h3 className="service-block_title">
                    <Link to="/services/interior-development">
                      Interior Development
                    </Link>
                  </h3>
                  {/* <p className="service-block_text">
                    Moertsarti market before enterprise wide e-commerce.
                    Competently actualize bleeding-edge matures.
                  </p> */}
                </div>
                <Link to="/services/interior-development" className="as-btn">
                  READ MORE
                  <i className="fas fa-arrow-right ms-2" />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="service-block">
                <div className="service-block_img">
                  <img
                    src="/assets/img/service/service_4_4.jpg"
                    alt="service"
                  />
                </div>
                <div className="service-block_content">
                  <div className="service-block_icon">
                    <img src="/assets/img/icon/service_1_4.svg" alt="Icon" />
                  </div>
                  <h3 className="service-block_title">
                    <Link to="/services/virtual-design">
                      Virtual design &amp; build
                    </Link>
                  </h3>
                  {/* <p className="service-block_text">
                    Gattisbart market before enterprise wide e-commerce.
                    Competently actualize bleeding-edge aman to.
                  </p> */}
                </div>
                <Link to="/services/virtual-design" className="as-btn">
                  READ MORE
                  <i className="fas fa-arrow-right ms-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Services;
