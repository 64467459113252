import Footer from "../components/Footer";
import Header from "../components/Header";
import catering from "../assets/catering.jpg";
import chemicals from "../assets/chemicals.jpg";
import ppe from "../assets/ppe.jpg";
import tugboat from "../assets/tugboat.jpg";

const Projects = () => {
  return (
    <>
      <Header />
      <section className="space">
        <div className="container">
          <div className="title-area text-center">
            <div className="shadow-title">PROJECTS</div>
            <span className="sub-title justify-content-center">
              <span className="shape left">
                <span className="dots" />
              </span>{" "}
              PROJECTS{" "}
              <span className="shape right">
                <span className="dots" />
              </span>
            </span>
            <h2 className="sec-title">Some Of Our Projects</h2>
          </div>
          <div className="row gy-40 filter-active">
            <div className="col-md-6 col-lg-4 filter-item cat3">
              <div className="project-card">
                <div className="project-img">
                  <img
                    src={catering}
                    alt="project"
                    style={{ height: "380px", objectFit: "cover" }}
                  />
                </div>
                <div className="project-content">
                  <h3 className="project-title">
                    <a href="#/">Catering</a>
                  </h3>
                  <a href="#/" className="project-icon">
                    <i className="far fa-plus" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 filter-item cat1">
              <div className="project-card">
                <div className="project-img">
                  <img
                    src={chemicals}
                    alt="project"
                    style={{ height: "380px", objectFit: "cover" }}
                  />
                </div>
                <div className="project-content">
                  <h3 className="project-title">
                    <a href="#/">Chemical Supplies</a>
                  </h3>
                  <a href="#/" className="project-icon">
                    <i className="far fa-plus" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 filter-item cat2">
              <div className="project-card">
                <div className="project-img">
                  <img
                    src={tugboat}
                    alt="project"
                    style={{ height: "380px", objectFit: "cover" }}
                  />
                </div>
                <div className="project-content">
                  <h3 className="project-title">
                    <a href="#/">Serviced Tugboats</a>
                  </h3>
                  <a href="#/" className="project-icon">
                    <i className="far fa-plus" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 filter-item cat1">
              <div className="project-card">
                <div className="project-img">
                  <img
                    src={ppe}
                    alt="project"
                    style={{ height: "380px", objectFit: "cover" }}
                  />
                </div>
                <div className="project-content">
                  <h3 className="project-title">
                    <a href="#/">PPE Equipment Supply</a>
                  </h3>
                  <a href="#/" className="project-icon">
                    <i className="far fa-plus" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Projects;
