import { Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Kitchen from "./pages/Kitchen";
import NotFound from "./pages/NotFound";
import Projects from "./pages/Projects";
import Catering from "./pages/projects/Catering";
import Chemical from "./pages/projects/Chemical";
import Ppe from "./pages/projects/Ppe";
import Service from "./pages/projects/Service";
import Services from "./pages/Services";
import IndustrialCatering from "./pages/services/IndustrialCatering";
import InteriorDev from "./pages/services/InteriorDev";
import Research from "./pages/services/Research";
import VirtualDesign from "./pages/services/VirtualDesign";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/isafred-kitchen" element={<Kitchen />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/projects/catering" element={<Catering />} />
      <Route path="/projects/chemical-supplies" element={<Chemical />} />
      <Route path="/projects/ppe" element={<Ppe />} />
      <Route path="/projects/tug-boats" element={<Service />} />
      <Route path="/services" element={<Services />} />
      <Route
        path="/services/industrial-catering"
        element={<IndustrialCatering />}
      />
      <Route path="/services/interior-development" element={<InteriorDev />} />
      <Route path="/services/research" element={<Research />} />
      <Route path="/services/virtual-design" element={<VirtualDesign />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
