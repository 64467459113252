import Footer from "../components/Footer";
import Header from "../components/Header";
import catering from "../assets/catering.jpg";

const Kitchen = () => {
  return (
    <>
      <Header />
      <section className="space-top space-extra-bottom">
        <div className="container">
          <div className="row flex-row">
            <div className="col-xxl-8 col-lg-7">
              <div className="page-single">
                <div className="page-img">
                  <img src={catering} alt="catering" />
                </div>
                <div className="page-content">
                  <h2 className="h3 page-title">Isafred Kitchen</h2>
                  <p>
                    Isafred continental kitchen is a subsidiary of Isafred
                    Limited (General Contractor and Suppliers). It is a catering
                    company registered in Nigeria. The business was established
                    out of passion for rendering good catering services. We are
                    readily available and willing to deliver on our client's
                    vision and prepared to make the most eventful and memorable
                    services. Isafred continental kitchen has consistently
                    maintained a high quality of service delivery with a team of
                    qualified and experienced personnel.
                  </p>
                  {/* <p className="mb-30">
                Authoritatively scale business meta-services before
                client-based technologies. Collaboratively strategize
                synergistic scenarios rather than flexible action items.
                Continually deliver market positioning convergence and
                mission-critical infrastructures.
              </p> */}
                  {/* <div className="row">
                <div className="col-xl-6 mb-20">
                  <img
                    src="/assets/img/service/service_inner_1.jpg"
                    alt="service"
                  />
                </div>
                <div className="col-xl-6 mb-20">
                  <h3 className="h5 mt-n1">Services Benefits:</h3>
                  <p>
                    Authoritatively scale business meta-services before
                    client-based technologies.
                  </p>
                  <div className="checklist style2">
                    <ul>
                      <li>
                        <h6 className="fs-md mb-1">Commercial Services</h6>
                        <p>
                          Collaboratively strategize synergistic scenarios
                          rather than flexible action items.
                        </p>
                      </li>
                      <li>
                        <h6 className="fs-md mb-1">Residensial Services</h6>
                        <p>
                          Collaboratively strategize synergistic scenarios
                          rather than flexible action items.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Kitchen;
