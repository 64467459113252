import Footer from "../components/Footer";
import Header from "../components/Header";

const Contact = () => {
  return (
    <>
      <Header />
      <div>
        <section className="space-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 mb-5 pb-lg-4">
                <h3 className="border-title2">Contact Info</h3>
                <div className="contact-info">
                  <h4 className="contact-info_title">Contact Number:</h4>
                  <div className="contact-info_icon">
                    <i className="fal fa-phone" />
                  </div>
                  <div className="media-body">
                    <span className="contact-info_text">
                      <a href="tel:+2348033383151">+2348033383151</a>{" "}
                      <a href="tel:+2348035160511">+2348035160511</a>
                    </span>
                  </div>
                </div>
                <div className="contact-info">
                  <h4 className="contact-info_title">Mail Address:</h4>
                  <div className="contact-info_icon">
                    <i className="fal fa-envelope" />
                  </div>
                  <div className="media-body">
                    <span className="contact-info_text">
                      <a href="mailto:isafred2000@yahoo.com">
                        isafred2000@yahoo.com
                      </a>{" "}
                    </span>
                  </div>
                </div>
                <div className="contact-info">
                  <h4 className="contact-info_title">Office Address:</h4>
                  <div className="contact-info_icon">
                    <i className="fal fa-location-dot" />
                  </div>
                  <div className="media-body">
                    <span className="contact-info_text">
                      SNCFPA Estate, Elelenwo, Port Harcourt
                      <br />
                      Rivers state, Nigeria
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="contact-map">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3975.5600565566947!2d7.073100914820719!3d4.845335041735437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1069cd9c4d1ca8d9%3A0xb27ccdc58e4121f8!2sSNCPFA%20Housing%20Estate!5e0!3m2!1sen!2sng!4v1675112275646!5m2!1sen!2sng"
            allowFullScreen
            loading="lazy"
          />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
