import { useState } from "react";
import MenuContext from "./menu-context";

const MenuProvider = (props: any) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const menuContext = {
    menuOpen,
    toggleMenu,
  };

  return (
    <MenuContext.Provider value={menuContext}>
      {props.children}
    </MenuContext.Provider>
  );
};

export default MenuProvider;
