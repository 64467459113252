import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const InteriorDev = () => {
  return (
    <>
      <Header />
      <section className="space-top space-extra-bottom">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-xxl-8 col-lg-7">
              <div className="page-single">
                <div className="page-img">
                  <img
                    src="/assets/img/service/service_4_3.jpg"
                    alt="interior"
                  />
                </div>
                <div className="page-content">
                  <h2 className="h3 page-title">Interior Development</h2>
                  <p>
                    Interior development refers to the process of designing,
                    planning, and creating the interior spaces of a building or
                    structure. Our service involves the selection of materials,
                    lighting, furniture, and finishes to create a functional and
                    aesthetically pleasing environment. It also involves
                    considering factors such as traffic flow, ergonomics,
                    accessibility, and acoustics to ensure that the space meets
                    the needs of its users. The goal of interior development is
                    to enhance the interior spaces of a building to make them
                    comfortable, attractive, and functional and we achieve this
                    through a combination of design, construction, and
                    renovation processes, and may involve the work of
                    architects, interior designers, contractors, and other
                    professionals.
                  </p>
                  {/* <p className="mb-30">
                    Authoritatively scale business meta-services before
                    client-based technologies. Collaboratively strategize
                    synergistic scenarios rather than flexible action items.
                    Continually deliver market positioning convergence and
                    mission-critical infrastructures.
                  </p> */}
                  {/* <div className="row">
                    <div className="col-xl-6 mb-20">
                      <img
                        src="/assets/img/service/service_inner_1.jpg"
                        alt="service"
                      />
                    </div>
                    <div className="col-xl-6 mb-20">
                      <h3 className="h5 mt-n1">Services Benefits:</h3>
                      <p>
                        Authoritatively scale business meta-services before
                        client-based technologies.
                      </p>
                      <div className="checklist style2">
                        <ul>
                          <li>
                            <h6 className="fs-md mb-1">Commercial Services</h6>
                            <p>
                              Collaboratively strategize synergistic scenarios
                              rather than flexible action items.
                            </p>
                          </li>
                          <li>
                            <h6 className="fs-md mb-1">Residensial Services</h6>
                            <p>
                              Collaboratively strategize synergistic scenarios
                              rather than flexible action items.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-lg-5">
              <aside className="sidebar-area">
                <div className="widget widget_nav_menu">
                  <h3 className="widget_title">Service List</h3>
                  <div className="menu-all-pages-container">
                    <ul className="menu">
                      <li>
                        <Link to="/services/industrial-catering">
                          Industrial Catering
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/research">Research & Analysis</Link>
                      </li>

                      <li>
                        <Link to="/services/virtual-design">
                          Virtual Design &amp; Build
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="widget widget_banner"
                  data-overlay="theme"
                  data-opacity={9}
                  data-bg-src="/assets/img/bg/widget_bg_1.jpg"
                >
                  <h4 className="widget_title">Need Any Help?</h4>
                  <div className="widget-banner">
                    <p className="banner-text">
                      Feel free to reach out to us at any time.
                    </p>
                    <a
                      href="mailto:isafred2000@yahoo.com"
                      className="banner-link"
                    >
                      <i className="fas fa-envelope" /> isafred2000@yahoo.com
                    </a>{" "}
                    <a href="tel:+2348033383151" className="banner-link">
                      <i className="fas fa-phone" /> +2348033383151
                    </a>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default InteriorDev;
