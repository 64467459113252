import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import research from "../../assets/research.jpg";

const Research = () => {
  return (
    <>
      <Header />
      <section className="space-top space-extra-bottom">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-xxl-8 col-lg-7">
              <div className="page-single">
                <div className="page-img">
                  <img src={research} alt="research" />
                </div>
                <div className="page-content">
                  <h2 className="h3 page-title">Reseach & Analysis</h2>
                  <p>
                    Research and analysis refers to the systematic investigation
                    and evaluation of data or information to make informed
                    decisions or conclusions. Our research and analysis involves
                    collecting and analyzing data from various sources,
                    synthesizing information, and presenting it in a meaningful
                    way to support decision-making which can be applied in
                    various fields such as business, finance, social sciences,
                    medicine, and many more. This is usually conducted using
                    qualitative or quantitative methods, depending on the nature
                    of the research question and the data available. Effective
                    research and analysis requires clear objectives,
                    well-designed methodology, and rigorous analysis to arrive
                    at accurate and reliable conclusions.
                  </p>
                  {/* <p className="mb-30">
                    Authoritatively scale business meta-services before
                    client-based technologies. Collaboratively strategize
                    synergistic scenarios rather than flexible action items.
                    Continually deliver market positioning convergence and
                    mission-critical infrastructures.
                  </p> */}
                  {/* <div className="row">
                    <div className="col-xl-6 mb-20">
                      <img
                        src="/assets/img/service/service_inner_1.jpg"
                        alt="service"
                      />
                    </div>
                    <div className="col-xl-6 mb-20">
                      <h3 className="h5 mt-n1">Services Benefits:</h3>
                      <p>
                        Authoritatively scale business meta-services before
                        client-based technologies.
                      </p>
                      <div className="checklist style2">
                        <ul>
                          <li>
                            <h6 className="fs-md mb-1">Commercial Services</h6>
                            <p>
                              Collaboratively strategize synergistic scenarios
                              rather than flexible action items.
                            </p>
                          </li>
                          <li>
                            <h6 className="fs-md mb-1">Residensial Services</h6>
                            <p>
                              Collaboratively strategize synergistic scenarios
                              rather than flexible action items.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-lg-5">
              <aside className="sidebar-area">
                <div className="widget widget_nav_menu">
                  <h3 className="widget_title">Service List</h3>
                  <div className="menu-all-pages-container">
                    <ul className="menu">
                      <li>
                        <Link to="/services/industrial-catering">
                          Industrial Catering
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/interior-development">
                          Interior Development
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/virtual-design">
                          Virtual Design &amp; Build
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="widget widget_banner"
                  data-overlay="theme"
                  data-opacity={9}
                  data-bg-src="/assets/img/bg/widget_bg_1.jpg"
                >
                  <h4 className="widget_title">Need Any Help?</h4>
                  <div className="widget-banner">
                    <p className="banner-text">
                      Feel free to reach out to us at any time.
                    </p>
                    <a
                      href="mailto:isafred2000@yahoo.com"
                      className="banner-link"
                    >
                      <i className="fas fa-envelope" /> isafred2000@yahoo.com
                    </a>{" "}
                    <a href="tel:+2348033383151" className="banner-link">
                      <i className="fas fa-phone" /> +2348033383151
                    </a>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Research;
