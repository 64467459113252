import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

const NotFound = () => {
  return (
    <>
      <Header />
      <div>
        <section className="space">
          <div className="container">
            <div className="error-img">
              <img src="/assets/img/bg/error_img.png" alt="404" />
            </div>
            <div className="error-content">
              <h3 className="error-title">Oops! That page can’t be found</h3>
              <p className="error-text">
                It looks like nothing was found at this location. Maybe try one
                of the links below.
              </p>
              <Link to="/" className="as-btn">
                <i className="fas fa-home me-2" />
                Back To Home
              </Link>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
