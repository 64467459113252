import { useContext } from "react";
import { Link } from "react-router-dom";
import MenuContext from "../store/menu-context";
import logo from "../assets/logo.svg";
import headerLogo from "../assets/logo.jpeg";

const Header = () => {
  const { menuOpen, toggleMenu } = useContext(MenuContext);

  return (
    <>
      <div className={`as-menu-wrapper ${menuOpen ? "as-body-visible" : ""}`}>
        <div className="as-menu-area text-center">
          <button className="as-menu-toggle" onClick={toggleMenu}>
            <i className="fal fa-times" />
          </button>
          <div className="mobile-logo" style={{ padding: "0" }}>
            <Link to="/">
              <img src={logo} alt="Logo" style={{ height: "120px" }} />
            </Link>
          </div>
          <div className="as-mobile-menu">
            <ul>
              <li>
                <Link
                  onClick={() => {
                    if (menuOpen) {
                      toggleMenu();
                    }
                  }}
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    if (menuOpen) {
                      toggleMenu();
                    }
                  }}
                  to="/about"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    if (menuOpen) {
                      toggleMenu();
                    }
                  }}
                  to="/services"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    if (menuOpen) {
                      toggleMenu();
                    }
                  }}
                  to="/projects"
                >
                  Projects
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    if (menuOpen) {
                      toggleMenu();
                    }
                  }}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className="as-header header-layout1">
        <div className="header-top">
          <div className="container">
            <div className="header-top-inner">
              <div className="row justify-content-center justify-content-md-between align-items-center gy-2">
                <div className="col-auto d-none d-md-block">
                  <div className="header-links d-none d-md-block">
                    <ul>
                      <li>
                        <i className="fal fa-phone" />
                        <a href="tel:+2348033383151">+2348033383151</a>
                      </li>
                      <li>
                        <i className="fal fa-envelope" />
                        <a href="mailto:isafred2000@yahoo.com">
                          isafred2000@yahoo.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="header-links">
                    <ul>
                      <li>
                        <i className="fal fa-globe" />
                        <a href="#/">RC: 246964</a>
                      </li>
                      <li>
                        <div className="header-social">
                          <a
                            href="https://www.facebook.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>{" "}
                          <a
                            href="https://www.twitter.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-twitter" />
                          </a>{" "}
                          <a
                            href="https://www.linkedin.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-linkedin-in" />
                          </a>
                          <a
                            href="https://www.instagram.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-instagram" />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-wrapper">
          <div className="sticky-active">
            <div className="menu-area">
              <div className="container">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto">
                    <div className="logo-style1">
                      <Link to="/">
                        <img
                          src={headerLogo}
                          alt="Logo"
                          className="logo-head"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <nav className="main-menu d-none d-lg-inline-block">
                          <ul>
                            <li>
                              <Link
                                onClick={() => {
                                  if (menuOpen) {
                                    toggleMenu();
                                  }
                                }}
                                to="/"
                              >
                                Home
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() => {
                                  if (menuOpen) {
                                    toggleMenu();
                                  }
                                }}
                                to="/about"
                              >
                                About Us
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() => {
                                  if (menuOpen) {
                                    toggleMenu();
                                  }
                                }}
                                to="/services"
                              >
                                Services
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() => {
                                  if (menuOpen) {
                                    toggleMenu();
                                  }
                                }}
                                to="/projects"
                              >
                                Projects
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() => {
                                  if (menuOpen) {
                                    toggleMenu();
                                  }
                                }}
                                to="/contact"
                              >
                                Contact Us
                              </Link>
                            </li>
                          </ul>
                        </nav>
                        <button
                          onClick={toggleMenu}
                          type="button"
                          className="as-menu-toggle d-inline-block d-lg-none"
                        >
                          <i className="far fa-bars" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
