// import { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import catering from "../assets/catering.jpg";
import research from "../assets/research.jpg";
import farm from "../assets/farm.jpg";
import kitchen from "../assets/kitchen.jpg";
import hero from "../assets/hero.jpg";

const Home = () => {
  // const [tabSelected, setTabSelected] = useState("mission");

  return (
    <>
      <Header />
      <div className="as-hero-wrapper hero-1">
        <div
          className="hero-slider-1 as-carousel"
          data-fade="true"
          data-slide-show={1}
          data-md-slide-show={1}
        >
          <div className="as-hero-slide">
            <div
              className="as-hero-bg"
              data-bg-src="/assets/img/hero/hero_bg_1_1.jpg"
              data-overlay="overlay2"
              data-opacity={9}
            />
            <div className="container">
              <div className="hero-style1">
                <span
                  className="hero-subtitle"
                  data-ani="slideinleft"
                  data-ani-delay="0.1s"
                >
                  # Welcome To Isafred
                </span>
                <h1
                  className="hero-title"
                  data-ani="slideinleft"
                  data-ani-delay="0.3s"
                >
                  PROVIDE GENERAL
                </h1>
                <h1
                  className="hero-title"
                  data-ani="slideinleft"
                  data-ani-delay="0.5s"
                >
                  CONTRACTING AND SUPPLY SOLUTIONS
                </h1>
                <p
                  className="hero-text"
                  data-ani="slideinleft"
                  data-ani-delay="0.6s"
                >
                  We specialize in providing top-notch catering services,
                  supplying top-quality products, and offering expert general
                  contracting services to our clients in the oil and gas as well
                  as other industries.
                </p>
                <div
                  className="btn-group"
                  data-ani="slideinleft"
                  data-ani-delay="0.7s"
                >
                  <Link to="/services" className="as-btn">
                    Our Services
                    <i className="fas fa-long-arrow-right ms-2" />
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div className="hero-img">
              <img src={hero} alt="hero" />{" "}
              <button data-slick-next=".hero-slider-1" className="icon-btn">
                <i className="far fa-long-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="space" id="about-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6">
              <div className="img-box1">
                <img src="/assets/img/normal/about_1_1.png" alt="About" />
                <div className="shape">
                  <img src="/assets/img/normal/about_shape_1.svg" alt="shape" />
                </div>
                <div
                  className="about-counter1"
                  data-bg-src="/assets/img/bg/bg_pattern_3.png"
                >
                  <h3 className="counter-title">
                    <span className="counter-number">24</span>
                  </h3>
                  <span className="counter-text">Years Experience</span>
                </div>
              </div>
            </div>
            <div className="col-xl-6 ps-3 ps-xl-5">
              <div className="title-area mb-30">
                <span className="sub-title">
                  ABOUT US{" "}
                  <span className="shape right">
                    <span className="dots" />
                  </span>
                </span>
                <h2 className="sec-title">
                  A General Contractor And Supply Company
                </h2>
              </div>
              <p className="mt-n2 mb-4">
                We offer a wide range of services, including catering for
                on-site personnel, supplying equipment and materials, and
                providing general contracting services for the construction and
                maintenance of oil and gas facilities. Our goal is to make your
                life easier by taking care of all the details, so you can focus
                on what you do best – running your business.
              </p>
              <div className="about-bg-shape">
                <div className="shape">
                  <img src="/assets/img/bg/bg_pattern_4.png" alt="bg" />
                </div>
                <div className="arrow-list-wrap">
                  <div className="arrow-list">
                    <ul>
                      <li>First Class Quality Service</li>
                      <li>Service with reasonable price</li>
                      <li>Immediate 24/7 Emergency</li>
                      <li>Dedicated Personnel</li>
                    </ul>
                  </div>
                </div>
                <div className="signature-box-wrap">
                  <Link to="/about" className="as-btn">
                    ABOUT US
                    <i className="fas fa-arrow-right ms-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="project-sec">
        <div
          className="container as-container bg-smoke2 space"
          data-bg-src="/assets/img/bg/bg_pattern_6.png"
        >
          <div className="container">
            <div className="project-card-wrap">
              <div className="row justify-content-lg-between align-items-end">
                <div className="col-lg-7 mb-n2 mb-lg-0">
                  <div className="title-area">
                    <span className="sub-title">
                      COMPANY SUBSIDIARIES
                      <span className="shape right">
                        <span className="dots" />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row" id="projectSlide1">
                <div
                  className="col-md-6 col-lg-4"
                  style={{ marginBottom: "36px" }}
                >
                  <div className="project-card">
                    <div className="project-img">
                      <img
                        src={kitchen}
                        alt="kitchen"
                        style={{ height: "300px", objectFit: "cover" }}
                      />
                    </div>
                    <div className="project-content">
                      <p className="project-subtitle">Catering</p>
                      <h3 className="project-title">
                        <Link to="/isafred-kitchen">Isafred Kitchen</Link>
                      </h3>
                      <a href="#/" className="project-icon">
                        <i className="far fa-plus" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="project-card">
                    <div className="project-img">
                      <img
                        src={farm}
                        alt="farm"
                        style={{ height: "300px", objectFit: "cover" }}
                      />
                    </div>
                    <div className="project-content">
                      <p className="project-subtitle">Agriculture</p>
                      <h3 className="project-title">
                        <a href="#/">Isafred Farms</a>
                      </h3>
                      <a href="#/" className="project-icon">
                        <i className="far fa-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bg-smoke2 space"
        data-bg-src="/assets/img/bg/service_bg_1.png"
      >
        <div className="container">
          <div className="row justify-content-lg-between align-items-end">
            <div className="col-lg-7 mb-n2 mb-lg-0">
              <div className="title-area">
                <span className="sub-title">
                  OUR SERVICES{" "}
                  <span className="shape right">
                    <span className="dots" />
                  </span>
                </span>
                <h2 className="sec-title">The Best Services For You</h2>
              </div>
            </div>
            <div className="col-auto">
              <div className="sec-btn">
                <Link to="/services" className="as-btn">
                  View All Services
                  <i className="fas fa-arrow-right ms-2" />
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-4 col-xl-3"
              style={{ marginBottom: "32px" }}
            >
              <div className="service-card">
                <div className="service-card_img">
                  <img
                    src={catering}
                    alt="catering"
                    style={{ height: "250px", objectFit: "cover" }}
                  />
                </div>
                <div
                  className="service-card_content"
                  data-bg-src="/assets/img/bg/bg_pattern_5.png"
                >
                  <h3 className="service-card_title">
                    <Link to="/services/industrial-catering">
                      Industrial Catering
                    </Link>
                  </h3>
                  {/* <p className="service-card_text">
                    Intrinsicly engage the resource maximizing process
                    improvements.
                  </p> */}
                  <div className="service-card_icon">
                    <img src="/assets/img/icon/service_1_1.svg" alt="Icon" />
                  </div>
                </div>
                <Link
                  to="/services/industrial-catering"
                  className="service-card_btn"
                >
                  <i className="far fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 col-xl-3"
              style={{ marginBottom: "32px" }}
            >
              <div className="service-card">
                <div className="service-card_img">
                  <img
                    src={research}
                    alt="research"
                    style={{ height: "250px", objectFit: "cover" }}
                  />
                </div>
                <div
                  className="service-card_content"
                  data-bg-src="/assets/img/bg/bg_pattern_5.png"
                >
                  <h3 className="service-card_title">
                    <Link to="/services/research">Research & Analysis</Link>
                  </h3>
                  <p className="service-card_text">
                    {/* Diestarbcse engage the resource maximizing process
                    temparatorse. */}
                  </p>
                  <div className="service-card_icon">
                    <img src="/assets/img/icon/service_1_2.svg" alt="Icon" />
                  </div>
                </div>
                <Link to="/services/research" className="service-card_btn">
                  <i className="far fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 col-xl-3"
              style={{ marginBottom: "32px" }}
            >
              <div className="service-card">
                <div className="service-card_img">
                  <img
                    src="/assets/img/service/service_1_3.jpg"
                    alt="service"
                    style={{ height: "250px", objectFit: "cover" }}
                  />
                </div>
                <div
                  className="service-card_content"
                  data-bg-src="/assets/img/bg/bg_pattern_5.png"
                >
                  <h3 className="service-card_title">
                    <Link to="/services/interior-development">
                      Interior Development
                    </Link>
                  </h3>
                  {/* <p className="service-card_text">
                    Moertsartis engage the resource maximizing process
                    gataronsonse.
                  </p> */}
                  <div className="service-card_icon">
                    <img src="/assets/img/icon/service_1_3.svg" alt="Icon" />
                  </div>
                </div>
                <Link
                  to="/services/interior-development"
                  className="service-card_btn"
                >
                  <i className="far fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 col-xl-3"
              style={{ marginBottom: "32px" }}
            >
              <div className="service-card">
                <div className="service-card_img">
                  <img
                    src="/assets/img/service/service_1_4.jpg"
                    alt="service"
                    style={{ height: "250px", objectFit: "cover" }}
                  />
                </div>
                <div
                  className="service-card_content"
                  data-bg-src="/assets/img/bg/bg_pattern_5.png"
                >
                  <h3 className="service-card_title">
                    <Link to="/services/virtual-design">
                      Virtual design &amp; build
                    </Link>
                  </h3>
                  {/* <p className="service-card_text">
                    Gattisbarti engage the resource maximizing process
                    metarialistic.
                  </p> */}
                  <div className="service-card_icon">
                    <img src="/assets/img/icon/service_1_4.svg" alt="Icon" />
                  </div>
                </div>
                <Link
                  to="/services/virtual-design"
                  className="service-card_btn"
                >
                  <i className="far fa-long-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="space process-sec"
        data-overlay="title"
        data-opacity={9}
        data-bg-src="/assets/img/bg/process_bg_1.jpg"
      >
        <div className="container mb-n2">
          <div className="title-area text-center">
            <span className="sub-title text-white justify-content-center">
              <span className="shape left">
                <span className="dots" />
              </span>{" "}
              HOW WE WORK{" "}
              <span className="shape right">
                <span className="dots" />
              </span>
            </span>
            <h2 className="sec-title text-white">Our Work Process</h2>
          </div>
          <div className="row gy-40 justify-content-between">
            <div className="col-sm-6 col-lg-auto process-card-wrap">
              <div className="process-card">
                <div className="process-card_icon">
                  <i className="fal fa-file-lines" />
                </div>
                <h2 className="process-card_title">Planning</h2>
                <p className="process-card_text">
                  Setting objectives, targets, and formulating plans to
                  accomplish.
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-auto process-card-wrap">
              <div className="process-card">
                <div className="process-card_icon">
                  <i className="fal fa-pen-ruler" />
                </div>
                <h2 className="process-card_title">Design</h2>
                <p className="process-card_text">
                  We create, fashion, execute or construct according to plan.
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-auto process-card-wrap">
              <div className="process-card">
                <div className="process-card_icon">
                  <i className="fal fa-user-helmet-safety" />
                </div>
                <h2 className="process-card_title">Implement</h2>
                <p className="process-card_text">
                  We get straight to work after planning and designing.
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-auto process-card-wrap">
              <div className="process-card">
                <div className="process-card_icon">
                  <i className="fal fa-shield-check" />
                </div>
                <h2 className="process-card_title">Finishing</h2>
                <p className="process-card_text">
                  The final step of the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="space">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-xl-7 ps-xxl-5">
              <div className="video-box1">
                <div className="img1">
                  <img src="/assets/img/normal/why_1.png" alt="video" />
                </div>
                <div className="text-end">
                  <div className="bg-shape bg-theme">
                    <img src="/assets/img/bg/bg_pattern_8.png" alt="shape" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="title-area mb-30">
                <span className="sub-title">
                  WHY CHOOSE US{" "}
                  <span className="shape right">
                    <span className="dots" />
                  </span>
                </span>
                <h2 className="sec-title">Why Choose Us</h2>
              </div>
              <p className="mt-n2 mb-30">
                At Isafred Limited, we pride ourselves on our commitment to
                safety, quality, and customer satisfaction. We are fully
                licensed and insured, and we follow all industry regulations and
                standards to ensure that our work is safe and reliable.
              </p>
              {/* <div className="mission-area">
                <div className="tab-menu1 filter-menu-active">
                  <button
                    className={`${tabSelected === "mission" ? "active" : ""}`}
                    type="button"
                    onClick={() => setTabSelected("mission")}
                  >
                    1
                  </button>{" "}
                  <button
                    className={`${tabSelected === "vision" ? "active" : ""}`}
                    type="button"
                    onClick={() => setTabSelected("vision")}
                  >
                    2
                  </button>{" "}
                  <button
                    className={`${tabSelected === "value" ? "active" : ""}`}
                    type="button"
                    onClick={() => setTabSelected("value")}
                  >
                    3
                  </button>
                </div>
                <div className="mission-box-wrap filter-active-cat1">
                  {tabSelected === "mission" && (
                    <div className="filter-item cat1">
                      <div className="mission-box">
                        <h3 className="h4 mission-title">Mission</h3>
                        <p className="mission-text">
                          Assertively deliver client-centered communities
                          without frictionless services.
                        </p>
                        <div className="checklist-wrap">
                          <div className="mission-img">
                            <img
                              src="/assets/img/normal/mission_1_1.jpg"
                              alt="mission img"
                            />
                          </div>
                          <div className="checklist style2">
                            <ul>
                              <li>Commercial Services</li>
                              <li>Residential Services</li>
                              <li>Industrial Services</li>
                              <li>Construction Service</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {tabSelected === "vision" && (
                    <div className="filter-item cat2">
                      <div className="mission-box">
                        <h3 className="h4 mission-title">Vision</h3>
                        <p className="mission-text">
                          Assertively deliver client-centered communities
                          without frictionless services.
                        </p>
                        <div className="checklist-wrap">
                          <div className="mission-img">
                            <img
                              src="/assets/img/normal/mission_1_2.jpg"
                              alt="mission img"
                            />
                          </div>
                          <div className="checklist style2">
                            <ul>
                              <li>Commercial Services</li>
                              <li>Residential Services</li>
                              <li>Industrial Services</li>
                              <li>Construction Service</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {tabSelected === "value" && (
                    <div className="filter-item cat3">
                      <div className="mission-box">
                        <h3 className="h4 mission-title">Value</h3>
                        <p className="mission-text">
                          Assertively deliver client-centered communities
                          without frictionless services.
                        </p>
                        <div className="checklist-wrap">
                          <div className="mission-img">
                            <img
                              src="/assets/img/normal/mission_1_3.jpg"
                              alt="mission img"
                            />
                          </div>
                          <div className="checklist style2">
                            <ul>
                              <li>Commercial Services</li>
                              <li>Residential Services</li>
                              <li>Industrial Services</li>
                              <li>Construction Service</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="z-index-common">
        <div
          className="container px-0 z-index-common"
          data-bg-src="/assets/img/bg/counter_bg_1.jpg"
          data-overlay="title"
          data-opacity={8}
          data-pos-for="#team-sec"
          data-sec-pos="bottom-half"
        >
          <div className="counter-card-wrap">
            <div className="row gy-30 justify-content-between">
              <div className="col-sm-6 col-xl-auto">
                <div className="counter-card">
                  <div className="counter-card_icon">
                    <img src="/assets/img/icon/counter_1_1.svg" alt="icon" />
                  </div>
                  <div className="media-body">
                    <h2 className="counter-card_number">
                      <span className="counter-number">500</span>+
                    </h2>
                    <p className="counter-card_text">Projects Completed</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-auto">
                <div className="counter-card">
                  <div className="counter-card_icon">
                    <img src="/assets/img/icon/counter_1_2.svg" alt="icon" />
                  </div>
                  <div className="media-body">
                    <h2 className="counter-card_number">
                      <span className="counter-number">30</span>+
                    </h2>
                    <p className="counter-card_text">Team Members</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-auto">
                <div className="counter-card">
                  <div className="counter-card_icon">
                    <img src="/assets/img/icon/counter_1_3.svg" alt="icon" />
                  </div>
                  <div className="media-body">
                    <h2 className="counter-card_number">
                      <span className="counter-number">1</span>k+
                    </h2>
                    <p className="counter-card_text">Happy Clients</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-6 col-xl-auto">
                <div className="counter-card">
                  <div className="counter-card_icon">
                    <img src="/assets/img/icon/counter_1_4.svg" alt="icon" />
                  </div>
                  <div className="media-body">
                    <h2 className="counter-card_number">
                      <span className="counter-number">1.1</span>k+
                    </h2>
                    <p className="counter-card_text">Award Wins</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div
        className="space-top pb-5 bg-top-left"
        data-bg-src="/assets/img/bg/bg_pattern_10.png"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 pe-xxl-5">
              <div className="title-area mb-30">
                <span className="sub-title">
                  CONTACT US{" "}
                  <span className="shape right">
                    <span className="dots" />
                  </span>
                </span>
                <h2 className="sec-title">To Get Any Help We’re Always Here</h2>
              </div>
              <p className="mt-n2 mb-30">
                Our team of experienced professionals is dedicated to meeting
                and exceeding your expectations. We understand the unique
                challenges and demands of the oil and gas industry and we work
                tirelessly to ensure that our clients have everything they need
                to run their operations smoothly and efficiently.
              </p>
              <div className="row gy-3 mb-35">
                <div className="col-md-auto col-sm-6">
                  <img src="/assets/img/normal/quote_1_1.jpg" alt="quote" />
                </div>
                <div className="col-md-auto col-sm-6">
                  <img src="/assets/img/normal/quote_1_2.jpg" alt="quote" />
                </div>
              </div>
              <div className="btn-wrap">
                <Link to="/contact" className="as-btn">
                  CONTACT US
                  <i className="fas fa-arrow-right ms-2" />
                </Link>{" "}
                <a href="tel:+2348033383151" className="call-btn">
                  <div className="play-btn">
                    <i className="fas fa-phone" />
                  </div>
                  +2348033383151
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
