import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import ScrollToTop from "./helpers/ScrollToTop";
import MenuProvider from "./store/MenuProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MenuProvider>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </MenuProvider>
);
