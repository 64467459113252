// import { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const About = () => {
  // const [tabSelected, setTabSelected] = useState("mission");

  return (
    <>
      <Header />
      <div>
        <div className="space" id="about-sec">
          <div className="container">
            <div className="row">
              <div className="col-xl-6">
                <div className="img-box1 img-box5">
                  <div className="img1">
                    <img src="/assets/img/normal/about_4_1.jpg" alt="About" />
                  </div>
                  <div className="img2">
                    <img src="/assets/img/normal/about_4_2.jpg" alt="About" />
                  </div>
                  <div className="shape">
                    <img
                      src="/assets/img/normal/about_shape_1.svg"
                      alt="shape"
                    />
                  </div>
                  <div
                    className="about-counter1"
                    data-bg-src="/assets/img/bg/bg_pattern_3.png"
                  >
                    <h3 className="counter-title">
                      <span className="counter-number">24</span>
                    </h3>
                    <span className="counter-text">Years Experience</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 ps-3 ps-xl-5">
                <div className="title-area mb-30">
                  <span className="sub-title">
                    ABOUT US{" "}
                    <span className="shape right">
                      <span className="dots" />
                    </span>
                  </span>
                  <h2 className="sec-title">
                    A General Contractor And Supply Company
                  </h2>
                </div>
                <p className="mt-n2 mb-4">
                  We offer a wide range of services, including catering for
                  on-site personnel, supplying equipment and materials, and
                  providing general contracting services for the construction
                  and maintenance of oil and gas facilities. Our goal is to make
                  your life easier by taking care of all the details, so you can
                  focus on what you do best – running your business.
                </p>
                <div className="about-bg-shape">
                  <div className="shape">
                    <img src="/assets/img/bg/bg_pattern_4.png" alt="bg" />
                  </div>
                  <div className="arrow-list-wrap">
                    <div className="arrow-list">
                      <ul>
                        <li>First Class Quality Service</li>
                        <li>service with reasonable price</li>
                        <li>Immediate 24/ 7 Emergency</li>
                        <li>Dedicated Personnel</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="z-index-common space-bottom">
          <div className="container">
            <div className="row gy-30 justify-content-between">
              <div className="col-sm-6 col-xl-auto">
                <div className="counter-card style2">
                  <div className="counter-card_icon">
                    <img src="/assets/img/icon/counter_1_1.svg" alt="icon" />
                  </div>
                  <div className="media-body">
                    <h2 className="counter-card_number text-title">
                      <span className="counter-number">500</span>+
                    </h2>
                    <p className="counter-card_text text-body">
                      Projects Completed
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-auto">
                <div className="counter-card style2">
                  <div className="counter-card_icon">
                    <img src="/assets/img/icon/counter_1_2.svg" alt="icon" />
                  </div>
                  <div className="media-body">
                    <h2 className="counter-card_number text-title">
                      <span className="counter-number">30</span>+
                    </h2>
                    <p className="counter-card_text text-body">Team Members</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-auto">
                <div className="counter-card style2">
                  <div className="counter-card_icon">
                    <img src="/assets/img/icon/counter_1_3.svg" alt="icon" />
                  </div>
                  <div className="media-body">
                    <h2 className="counter-card_number text-title">
                      <span className="counter-number">1</span>k+
                    </h2>
                    <p className="counter-card_text text-body">Happy Clients</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-6 col-xl-auto">
                <div className="counter-card style2">
                  <div className="counter-card_icon">
                    <img src="/assets/img/icon/counter_1_4.svg" alt="icon" />
                  </div>
                  <div className="media-body">
                    <h2 className="counter-card_number text-title">
                      <span className="counter-number">1.1</span>k+
                    </h2>
                    <p className="counter-card_text text-body">Award Wins</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div>
          <div
            className="space"
            id="video-sec"
            data-bg-src="/assets/img/bg/video_bg_1.jpg"
            data-overlay="title"
            data-opacity={7}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-9 col-lg-10 text-center">
                  <h2 className="mb-n2 text-white">
                    Your One-stop Solution For General Contracting And Supply
                    Needs
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-3">
          <div
            className="as-container4 space bg-white shadow-1"
            data-pos-space="#video-sec"
            data-sec-space="margin-top"
            data-margin-top="290px"
          >
            <div className="container">
              <div className="row flex-row-reverse">
                <div className="col-xl-7 ps-xxl-5">
                  <div className="img-box6">
                    <div className="img1">
                      <img src="/assets/img/normal/why_4.png" alt="why" />
                    </div>
                    <div className="img2">
                      <img src="/assets/img/normal/why_5.png" alt="why" />
                    </div>
                  </div>
                </div>
                <div className="col-xl-5">
                  <div className="title-area mb-30">
                    <span className="sub-title">
                      WHY CHOOSE US{" "}
                      <span className="shape right">
                        <span className="dots" />
                      </span>
                    </span>
                    <h2 className="sec-title">Why Choose Us</h2>
                  </div>
                  <p className="mt-n2 mb-30">
                    At Isafred Limited, we pride ourselves on our commitment to
                    safety, quality, and customer satisfaction. We are fully
                    licensed and insured, and we follow all industry regulations
                    and standards to ensure that our work is safe and reliable.
                  </p>
                  {/* <div className="mission-area">
                    <div className="tab-menu4 filter-menu-active">
                      <button
                        onClick={() => setTabSelected("mission")}
                        className={tabSelected === "mission" ? "active" : ""}
                        type="button"
                      >
                        MISSION
                      </button>{" "}
                      <button
                        onClick={() => setTabSelected("vision")}
                        className={tabSelected === "vision" ? "active" : ""}
                        type="button"
                      >
                        VISION
                      </button>{" "}
                      <button
                        onClick={() => setTabSelected("value")}
                        className={tabSelected === "value" ? "active" : ""}
                        type="button"
                      >
                        VALUE
                      </button>
                    </div>
                    <div className="mission-box-wrap filter-active-cat1">
                      {tabSelected === "mission" && (
                        <div className="filter-item cat1">
                          <div className="mission-box">
                            <h3 className="h4 mission-title">Mission</h3>
                            <p className="mission-text">
                              Assertively deliver client-centered communities
                              without frictionless services.
                            </p>
                            <div className="checklist-wrap">
                              <div className="mission-img">
                                <img
                                  src="/assets/img/normal/mission_1_1.jpg"
                                  alt="mission img"
                                />
                              </div>
                              <div className="checklist style2">
                                <ul>
                                  <li>Commercial Services</li>
                                  <li>Residential Services</li>
                                  <li>Industrial Services</li>
                                  <li>Construction Service</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {tabSelected === "vision" && (
                        <div className="filter-item cat2">
                          <div className="mission-box">
                            <h3 className="h4 mission-title">Vision</h3>
                            <p className="mission-text">
                              Assertively deliver client-centered communities
                              without frictionless services.
                            </p>
                            <div className="checklist-wrap">
                              <div className="mission-img">
                                <img
                                  src="/assets/img/normal/mission_1_2.jpg"
                                  alt="mission img"
                                />
                              </div>
                              <div className="checklist style2">
                                <ul>
                                  <li>Commercial Services</li>
                                  <li>Residential Services</li>
                                  <li>Industrial Services</li>
                                  <li>Construction Service</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {tabSelected === "value" && (
                        <div className="filter-item cat3">
                          <div className="mission-box">
                            <h3 className="h4 mission-title">Value</h3>
                            <p className="mission-text">
                              Assertively deliver client-centered communities
                              without frictionless services.
                            </p>
                            <div className="checklist-wrap">
                              <div className="mission-img">
                                <img
                                  src="/assets/img/normal/mission_1_3.jpg"
                                  alt="mission img"
                                />
                              </div>
                              <div className="checklist style2">
                                <ul>
                                  <li>Commercial Services</li>
                                  <li>Residential Services</li>
                                  <li>Industrial Services</li>
                                  <li>Construction Service</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default About;
